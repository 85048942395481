import { configureStore } from '@reduxjs/toolkit';
import cardReducer from './slices/cardSlice';
import depositReducer from './slices/depositSlice';
import matchReducer from './slices/matchSlice';
import playerReducer from './slices/playerSlice';
import ratingReducer from './slices/ratingSlice';
import referralReducer from './slices/referralSlice';
import shareReducer from './slices/shareSlice';
import taskReducer from './slices/taskSlice';
import toastReducer from './slices/toastSlice';

export const store = configureStore({
  reducer: {
    player: playerReducer,
    card: cardReducer,
    task: taskReducer,
    referral: referralReducer,
    toast: toastReducer,
    rating: ratingReducer,
    share: shareReducer,
    deposit: depositReducer,
    match: matchReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
