import * as S from './Match.styled';
import { createBoard, dropTiles, removeMatches, selectBoard } from '@src/redux/slices/matchSlice';
import { selectAvailableTaps, selectMaxTaps } from '@src/redux/slices/playerSlice';
import { useAppDispatch } from '@common/hooks';
import { useSelector } from 'react-redux';
import Capital from '@layouts/Capital';
import React, { FC, memo, useEffect } from 'react';
import SVG from '@ui/SVG/SVG';

const getColorByIndex = (index: number): string => {
  if (index === 1) {
    return '#6EF595';
  }
  if (index === 2) {
    return '#F5D86E';
  }
  if (index === 3) {
    return '#6E79F5';
  }
  if (index === 4) {
    return '#F56E6E';
  }
  if (index === 5) {
    return '#BDF56E';
  }
  if (index === 6) {
    return '#6ED9F5';
  }
  return '#D855F4';
};

const Match: FC = () => {
  const dispatch = useAppDispatch();
  const board = useSelector(selectBoard);
  const availableTaps = useSelector(selectAvailableTaps);
  const maxTaps = useSelector(selectMaxTaps);

  useEffect(() => {
    dispatch(createBoard());
    (window as any).dropTiles = () => {
      dispatch(dropTiles());
    };
    (window as any).removeMatches = () => {
      dispatch(removeMatches());
    };
  }, [dispatch]);

  return (
    <Capital title="Майнинг">
      <S.Table>
        {board.map((tileRow, rowIndex) => tileRow.map((tile, tileIndex) => (
          <S.Cell key={`${rowIndex}-${tileIndex}`}>
            {tile !== null && (
              <SVG
                name="mining"
                customColor={getColorByIndex(tile)}
                width={32}
                height={32}
              />
            )}
          </S.Cell>
        )))}
      </S.Table>
      <S.TapFooter>
        <S.Energy>
          <SVG
            name="lightning"
            customColor="#FCA100"
          />
          <S.EnergyText>
            {availableTaps > 0 ? Math.floor(availableTaps) : 0} / {maxTaps}
          </S.EnergyText>
        </S.Energy>
      </S.TapFooter>
    </Capital>
  );
};

export default memo(Match);
