import { createAppSlice } from '@common/hooks';

type TMatchState = {
  board: Array<Array<number|null>>;
}

const size = 6;

export const matchSlice = createAppSlice({
  name: 'match',
  initialState: {
    board: [],
  } as TMatchState,
  reducers: create => ({
    createBoard: create.reducer(state => {
      const board: Array<Array<number|null>> = [];
      for (let i = 0; i < size; i += 1) {
        board[i] = [];
        for (let j = 0; j < size; j += 1) {
          board[i][j] = Math.floor(Math.random() * 7 + 1);
        }
      }
      state.board = board;
    }),
    dropTiles: create.reducer(state => {
      for (let j = 0; j < size; j += 1) {
        let emptySlots = 0;
        for (let i = size - 1; i >= 0; i -= 1) {
          if (!state.board[i][j]) {
            emptySlots += 1;
          } else if (emptySlots > 0) {
            state.board[i + emptySlots][j] = state.board[i][j];
            state.board[i][j] = null;
          }
        }
        while (emptySlots > 0) {
          state.board[emptySlots - 1][j] = Math.floor(Math.random() * 6 + 1);
          emptySlots -= 1;
        }
      }
    }),
    swapTiles: create.reducer<[[number, number], [number, number]]>((state, action) => {
      const temp = state.board[action.payload[0][0]][action.payload[1][0]];
      state.board[action.payload[0][0]][action.payload[1][0]] = state.board[action.payload[0][1]][action.payload[1][1]];
      state.board[action.payload[0][1]][action.payload[1][1]] = temp;
    }),
    removeMatches: create.reducer(state => {
      const matches = [];

      for (let i = 0; i < size; i += 1) {
        for (let j = 0; j < size - 2; j += 1) {
          if (state.board[i][j] === state.board[i][j + 1] && state.board[i][j] === state.board[i][j + 2]) {
            matches.push([i, j], [i, j + 1], [i, j + 2]);
          }
        }
      }

      for (let j = 0; j < size; j += 1) {
        for (let i = 0; i < size - 2; i += 1) {
          if (state.board[i][j] === state.board[i + 1][j] && state.board[i][j] === state.board[i + 2][j]) {
            matches.push([i, j], [i + 1, j], [i + 2, j]);
          }
        }
      }

      matches.forEach(([i, j]) => {
        state.board[i][j] = null;
      });
    }),
  }),
  selectors: {
    selectBoard: state => state.board,
  },
});

export const { createBoard, dropTiles, swapTiles, removeMatches } = matchSlice.actions;
export const { selectBoard } = matchSlice.selectors;

export default matchSlice.reducer;
