import styled from 'styled-components';

export const Table = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border: 1px solid #65450D;
`;

export const Cell = styled.div`
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(43, 46, 66, 0.6);
    
    &:not(:nth-last-child(-n+6)) {
        border-bottom: 1px solid #65450D;
    }

    &:not(:nth-child(6n)) {
        border-right: 1px solid #65450D;
    }
`;

export const TapFooter = styled.div`
    margin: 24px 0 16px;
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const Energy = styled.div`
    display: flex;
    align-items: center;
    margin-right: 27px;
`;

export const EnergyText = styled.div`
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
`;
